// UnitSelector.js
import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  onSnapshot,
  orderBy
} from 'firebase/firestore';
import { auth, db } from '../../firebase/firebase-config';
import { ListGroup, Container, Badge, Spinner } from 'react-bootstrap';
import './UnitSelector.css';

/**
 * UnitSelector fetches:
 * - "units" from the "units" collection
 * - "chatRooms" from the "chatRooms" collection (custom private rooms)
 * - Hardcoded rooms (if you want them)
 * Then displays them in one unified list.
 * 
 * onSelectUnit(...) is called when the user chooses an item,
 * passing that item (unit or room) to the parent (Chat.js).
 */
function UnitSelector({ onSelectUnit }) {
  const [units, setUnits] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [userUnit, setUserUnit] = useState('');
  const [userRole, setUserRole] = useState('');
  const [unreadCounts, setUnreadCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true); // New loading state

  // Hardcoded rooms (example)
  const allHardcodedRooms = [
    { id: 'general', roomName: 'Splošno', accessibleToAll: true },
    {
      id: 'conference',
      roomName: 'Konferenca sindikatov zavarovalništva Slovenije',
      accessibleToRoles: ['admin', 'local-admin']
    }
  ];

  // Filtered hardcoded rooms based on user role
  const getFilteredHardcodedRooms = (role) => {
    if (role === 'user') {
      // Exclude the conference room for users with role 'user'
      return allHardcodedRooms.filter(room => room.id !== 'conference');
    }
    // Include all rooms for other roles
    return allHardcodedRooms;
  };

  useEffect(() => {
    const fetchData = async () => {
      // Must have a logged-in user
      if (!auth.currentUser) return;

      try {
        // 1) Fetch current user's profile (role, unit, etc.)
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUserUnit(userData.unit);
          setUserRole(userData.role || 'user');
        }

        // 2) Fetch "units" based on user role
        let unitsList = [];
        if (userRole === 'user') {
          if (userUnit) {
            const unitDocRef = doc(db, 'units', userUnit);
            const unitDocSnap = await getDoc(unitDocRef);
            if (unitDocSnap.exists()) {
              unitsList.push({
                id: unitDocSnap.id,
                ...unitDocSnap.data()
              });
            }
          }
        } else {
          // For admin or other roles, fetch all units
          const unitsCollectionRef = collection(db, 'units');
          const unitsSnapshot = await getDocs(unitsCollectionRef);
          unitsList = unitsSnapshot.docs.map(docItem => ({
            id: docItem.id,
            ...docItem.data()
          }));
        }
        setUnits(unitsList);

        // 3) Fetch "chatRooms" based on user role
        let chatRoomsList = [];
        if (userRole === 'user') {
          // For users, fetch chatRooms that are accessible to them
          // This includes:
          // - accessibleToAll == true
          // - allowedUsers array contains user UID
          // - unit == userUnit

          const accessibleToAllQuery = query(
            collection(db, 'chatRooms'),
            where('accessibleToAll', '==', true)
          );

          const allowedUsersQuery = query(
            collection(db, 'chatRooms'),
            where('allowedUsers', 'array-contains', auth.currentUser.uid)
          );

          let unitQuerySnapshot = [];
          if (userUnit) {
            const unitSpecificQuery = query(
              collection(db, 'chatRooms'),
              where('unit', '==', userUnit)
            );
            const unitSnapshot = await getDocs(unitSpecificQuery);
            unitQuerySnapshot = unitSnapshot.docs.map(docItem => ({
              id: docItem.id,
              ...docItem.data()
            }));
          }

          const accessibleToAllSnapshot = await getDocs(accessibleToAllQuery);
          const accessibleToAllList = accessibleToAllSnapshot.docs.map(docItem => ({
            id: docItem.id,
            ...docItem.data()
          }));

          const allowedUsersSnapshot = await getDocs(allowedUsersQuery);
          const allowedUsersList = allowedUsersSnapshot.docs.map(docItem => ({
            id: docItem.id,
            ...docItem.data()
          }));

          // Merge all lists and remove duplicates
          const mergedChatRooms = [...accessibleToAllList, ...allowedUsersList, ...unitQuerySnapshot];
          const uniqueChatRoomsMap = new Map();
          mergedChatRooms.forEach(room => {
            uniqueChatRoomsMap.set(room.id, room);
          });
          chatRoomsList = Array.from(uniqueChatRoomsMap.values());
        } else {
          // For admin or other roles, fetch all chatRooms
          const chatRoomsCollectionRef = collection(db, 'chatRooms');
          const chatRoomsSnapshot = await getDocs(chatRoomsCollectionRef);
          chatRoomsList = chatRoomsSnapshot.docs.map(docItem => ({
            id: docItem.id,
            ...docItem.data()
          }));
        }

        setChatRooms(chatRoomsList);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Optionally, set an error state here to inform the user
      } finally {
        setIsLoading(false); // Data fetching complete
      }
    };

    fetchData();
  }, [userRole, userUnit]); // Depend on userRole and userUnit

  /**
   * Listen for new messages to update unread counts in real-time
   * The logic here is similar to your existing approach:
   * We take lastRead timestamps from userDoc,
   * then for each unitId or chatRoomId, we open a query for messages > lastRead
   */
  useEffect(() => {
    const fetchUnreadCounts = async () => {
      if (!auth.currentUser) return;

      try {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        const lastReadTimestamps = userDocSnap.data()?.lastRead?.chat || {};

        const unsubscribes = [];

        // Determine which chatRooms to listen to based on user role
        const relevantChatRooms = chatRooms;

        relevantChatRooms.forEach(room => {
          const roomId = room.id;
          const lastRead = lastReadTimestamps[roomId] || 0;

          const q = query(
            collection(db, 'messages'),
            where('chatRoomId', '==', roomId),
            where('createdAt', '>', lastRead),
            orderBy('createdAt')
          );

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setUnreadCounts(prevCounts => ({
              ...prevCounts,
              [roomId]: querySnapshot.size
            }));
          });

          unsubscribes.push(unsubscribe);
        });

        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
      } catch (error) {
        console.error('Error fetching unread counts:', error);
      }
    };

    fetchUnreadCounts();
  }, [chatRooms]);

  /**
   * When the user clicks on an item (could be a standard "unit" or a "chatRoom"),
   * we check if they have access, then call onSelectUnit(...) so Chat.js can open it.
   */
  const handleItemClick = (item) => {
    // If it's a chatRoom, it might have 'roomName' instead of 'unitName'.
    const isChatRoom = !!item.roomName;

    if (isChatRoom) {
      // Access control based on user role and chatRoom properties
      if (userRole === 'admin' || userRole === 'local-admin') {
        onSelectUnit(item);
        return;
      }

      // Check if the room is accessible to all
      if (item.accessibleToAll) {
        onSelectUnit(item);
        return;
      }

      // Check if the user is explicitly allowed
      if (item.allowedUsers && item.allowedUsers.includes(auth.currentUser.uid)) {
        onSelectUnit(item);
        return;
      }

      // Check if the chatRoom is associated with the user's unit
      if (item.unit === userUnit) {
        onSelectUnit(item);
        return;
      }

      // If none of the above, access is denied
      alert('Nimate dostopa do te klepetalnice.');
    } else {
      // It's a standard "unit"
      // Re-use your existing logic
      if (
        item.accessibleToAll ||
        userRole === 'admin' ||
        userRole === 'local-admin' ||
        (item.accessibleToRoles && item.accessibleToRoles.includes(userRole)) ||
        item.id === userUnit
      ) {
        onSelectUnit(item);
      } else {
        alert('Nimate dostopa do te enote.');
      }
    }
  };

  /**
   * Renders the combined list: hardcodedRooms, units, and dynamic chatRooms
   */
  const renderList = () => {
    // Filter hardcoded rooms based on user role
    const filteredHardcodedRooms = getFilteredHardcodedRooms(userRole);

    // We'll unify them in one array, adding a simple check if an object has `roomName` or `unitName`.
    // For the "hardcodedRooms", we assume they are shaped like chatRooms (with roomName).
    // You can adjust if you want them to be "units" instead.

    const allItems = [
      ...filteredHardcodedRooms,   // Possibly your "general" or "conference" rooms
      ...units,                     // Real "units"
      ...chatRooms                  // Real "chatRooms"
    ];

    return allItems.map(item => {
      const nameToShow = item.roomName || item.unitName;
      const itemId = item.id;
      // We'll pull the unread count from unreadCounts if it exists
      const count = unreadCounts[itemId];

      return (
        <ListGroup.Item
          key={itemId}
          onClick={() => handleItemClick(item)}
          className="d-flex justify-content-between align-items-start unit-list-item"
          action
          style={{ cursor: 'pointer' }}
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{nameToShow}</div>
          </div>
          {count && count > 0 && (
            <Badge bg="danger" pill>{count}</Badge>
          )}
        </ListGroup.Item>
      );
    });
  };

  return (
    <Container>
      {isLoading ? (
        // Show a spinner or loading indicator while data is being fetched
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <ListGroup className="unit-list">{renderList()}</ListGroup>
      )}
    </Container>
  );
}

export default UnitSelector;
