import React, { useState, useEffect } from 'react';
import {
  collection,
  getDoc,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc
} from 'firebase/firestore';
import { Container, Table, Button, Form, Modal } from 'react-bootstrap';
import { auth, db } from '../../firebase/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import * as XLSX from 'xlsx'; // NEW: import xlsx
import './UserManagement.css';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [units, setUnits] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newUnitName, setNewUnitName] = useState("");
  const [newUnitDescription, setNewUnitDescription] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      if (!auth.currentUser) return;
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsAdmin(docSnap.data().role === 'admin');
      }
    };

    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(docItem => ({
        id: docItem.id,
        ...docItem.data()
      }));
      setUsers(usersList);
    };

    const fetchUnits = async () => {
      const unitsCollection = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollection);
      const unitsList = unitsSnapshot.docs.map(docItem => ({
        id: docItem.id,
        ...docItem.data()
      }));
      setUnits(unitsList);
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProfile();
        fetchUsers();
        fetchUnits();
      }
    });
  }, []);

  const startEditing = (userObj) => {
    setEditingUser({ ...userObj });
  };

  const cancelEditing = () => {
    setEditingUser(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleUnitChange = (e) => {
    setEditingUser((prev) => ({ ...prev, unit: e.target.value }));
  };

  const saveChanges = async () => {
    if (!editingUser) return;
    const userDocRef = doc(db, 'users', editingUser.id);
    await updateDoc(userDocRef, editingUser);
    setUsers(users.map(u => (u.id === editingUser.id ? editingUser : u)));
    setEditingUser(null);
  };

  const handleAddUnit = async () => {
    const unitsCollectionRef = collection(db, 'units');
    await addDoc(unitsCollectionRef, {
      unitName: newUnitName,
      description: newUnitDescription
    });
    setNewUnitName("");
    setNewUnitDescription("");

    // Refresh units
    const unitsSnapshot = await getDocs(unitsCollectionRef);
    const unitsList = unitsSnapshot.docs.map(docItem => ({
      id: docItem.id,
      ...docItem.data()
    }));
    setUnits(unitsList);
  };

  const handleDeleteUnit = async (unitId) => {
    if (window.confirm('Ali ste prepričani, da želite izbrisati to enoto?')) {
      await deleteDoc(doc(db, 'units', unitId));
      setUnits(units.filter(u => u.id !== unitId));
    }
  };

  // NEW: Admin exports user data to Excel
  const handleExportUsers = () => {
    // Transform user data into a JSON array for XLSX
    const exportData = users.map((u) => {
      const unitObj = units.find(unit => unit.id === u.unit);
      return {
        Email: u.email || '',
        'Prikazno ime': u.displayName || '',
        Ime: u.firstName || '',
        Priimek: u.lastName || '',
        Telefon: u.phone || '',
        Enota: unitObj?.unitName || 'N/A',
        Vloga: u.role || ''
      };
    });

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Uporabniki");
    // Export to Excel file
    XLSX.writeFile(workbook, "Seznam_uporabnikov.xlsx");
  };

  // If not admin => no access
  if (!isAdmin) {
    return <div>You do not have access to this page.</div>;
  }

  return (
    <div className="full-bg">
      <Container className="user-management-container mt-3">
        <h1 className="user-management-title">Upravljanje</h1>
        <div className="mb-3">
          {/* "Upravljanje enot" button */}
          <Button variant="primary" onClick={() => setShowModal(true)}>
            Upravljanje enot
          </Button>{' '}
          {/* "Izvozi uporabnike" button (only for admin) */}
          <Button variant="success" onClick={handleExportUsers}>
            Izvozi seznam uporabnikov
          </Button>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Email</th>
              <th>Prikazno ime</th>
              <th>Ime</th>
              <th>Priimek</th>
              <th>Telefon</th>
              <th>Enota</th>
              <th>Vloga</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((userObj) => (
              <tr key={userObj.id}>
                {editingUser && editingUser.id === userObj.id ? (
                  <>
                    <td>{userObj.email}</td>
                    <td>
                      <Form.Control
                        type="text"
                        name="displayName"
                        value={editingUser.displayName || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={editingUser.firstName || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={editingUser.lastName || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={editingUser.phone || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="unit"
                        value={editingUser.unit || ''}
                        onChange={handleUnitChange}
                      >
                        {units.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.unitName}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        name="role"
                        value={editingUser.role || 'user'}
                        onChange={handleInputChange}
                      >
                        <option value="user">Uporabnik</option>
                        <option value="local-admin">Administrator enote</option>
                        <option value="admin">Administrator</option>
                      </Form.Control>
                    </td>
                    <td>
                      <Button variant="success" onClick={saveChanges}>
                        Shrani
                      </Button>{' '}
                      <Button variant="secondary" onClick={cancelEditing}>
                        Prekliči
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{userObj.email}</td>
                    <td>{userObj.displayName}</td>
                    <td>{userObj.firstName}</td>
                    <td>{userObj.lastName}</td>
                    <td>{userObj.phone}</td>
                    <td>
                      {units.find((u) => u.id === userObj.unit)?.unitName || 'N/A'}
                    </td>
                    <td>{userObj.role}</td>
                    <td>
                      <Button variant="primary" onClick={() => startEditing(userObj)}>
                        Uredi
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Upravljanje enot</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Dodaj novo enoto</h5>
            <Form.Group controlId="formNewUnitName">
              <Form.Label>Ime enote</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ime enote"
                value={newUnitName}
                onChange={(e) => setNewUnitName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNewUnitDescription" className="mt-3">
              <Form.Label>Opis enote</Form.Label>
              <Form.Control
                type="text"
                placeholder="Opis enote"
                value={newUnitDescription}
                onChange={(e) => setNewUnitDescription(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" className="mt-3" onClick={handleAddUnit}>
              Dodaj enoto
            </Button>
            <hr />
            <h5>Seznam enot</h5>
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Ime enote</th>
                  <th>Opis</th>
                  <th>Akcije</th>
                </tr>
              </thead>
              <tbody>
                {units.map((unit) => (
                  <tr key={unit.id}>
                    <td>{unit.unitName}</td>
                    <td>{unit.description}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteUnit(unit.id)}
                      >
                        Izbriši
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Zapri
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default UserManagement;
