// src/components/polls/PollCreateForm.js

import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Container,
  Row,
  Col,
  Alert,
  Accordion,
  Card,
  ProgressBar
} from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, auth, storage } from '../../firebase/firebase-config';
import { useUser } from '../../context/UserContext';

// Import from our new emailUtils
import {
  getAllUserEmails,
  getUserEmailsByUnit,
  sendMassEmail
} from '../../utils/emailUtils';

import './PollCreateForm.css';

function PollCreateForm({ onPollCreated, pollToEdit }) {
  const { user } = useUser();

  // ─────────────────────────────────────────────────────────────
  // State variables
  // ─────────────────────────────────────────────────────────────
  const [title, setTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [expires, setExpires] = useState('');
  const [error, setError] = useState('');
  const [showResultsAfterVote, setShowResultsAfterVote] = useState(true);
  const [unit, setUnit] = useState('general');
  const [unitName, setUnitName] = useState('Splošno');
  const [units, setUnits] = useState([]);
  const [anonymous, setAnonymous] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // NEW: Switches for email sending
  const [sendEmailAll, setSendEmailAll] = useState(false);
  const [sendEmailUnit, setSendEmailUnit] = useState(false);

  // ─────────────────────────────────────────────────────────────
  // Fetch units from Firestore (filter if local-admin)
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchUnits = async () => {
      const unitsSnapshot = await getDocs(collection(db, 'units'));
      const allUnits = unitsSnapshot.docs.map(docItem => ({
        id: docItem.id,
        ...docItem.data()
      }));

      // If local-admin, filter only the user's unit + 'general'
      let filteredUnits = allUnits;
      if (user?.role === 'local-admin') {
        filteredUnits = allUnits.filter(
          u => u.id === user.unit || u.id === 'general'
        );
      }
      setUnits(filteredUnits);
    };

    fetchUnits();
  }, [user]);

  // ─────────────────────────────────────────────────────────────
  // If editing existing poll, populate the form
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (pollToEdit) {
      setTitle(pollToEdit.title);
      setQuestions(pollToEdit.questions);
      setShowResultsAfterVote(pollToEdit.showResultsAfterSubmit ?? true);
      setUnit(pollToEdit.unit ?? 'general');
      setUnitName(pollToEdit.unitName ?? 'Splošno');
      setAnonymous(pollToEdit.anonymous ?? false);

      if (pollToEdit.expires) {
        try {
          if (pollToEdit.expires.toDate) {
            setExpires(
              new Date(pollToEdit.expires.toDate())
                .toISOString()
                .substring(0, 16)
            );
          } else if (!isNaN(new Date(pollToEdit.expires))) {
            setExpires(
              new Date(pollToEdit.expires).toISOString().substring(0, 16)
            );
          } else {
            setExpires('');
          }
        } catch (err) {
          console.error('Invalid expires value:', pollToEdit.expires);
          setExpires('');
        }
      }
    }
  }, [pollToEdit]);

  // ─────────────────────────────────────────────────────────────
  // Add / remove questions + question editing
  // ─────────────────────────────────────────────────────────────
  const addQuestion = () => {
    setQuestions(prev => [
      ...prev,
      { question: '', type: 'radio', options: [''], allowCustomAnswer: false }
    ]);
  };

  const removeQuestion = index => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);
  };

  // ─────────────────────────────────────────────────────────────
  // Options logic for radio/checkbox
  // ─────────────────────────────────────────────────────────────
  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = value;
    setQuestions(newQuestions);
  };

  const addOption = qIndex => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.push('');
    setQuestions(newQuestions);
  };

  // ─────────────────────────────────────────────────────────────
  // Unit selection
  // ─────────────────────────────────────────────────────────────
  const handleUnitChange = e => {
    const selectedUnitId = e.target.value;
    const selectedUnit = units.find(u => u.id === selectedUnitId);
    setUnit(selectedUnitId);
    setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
  };

  // ─────────────────────────────────────────────────────────────
  // React-beautiful-dnd reordering
  // ─────────────────────────────────────────────────────────────
  const onDragEnd = result => {
    if (!result.destination) return;
    const newQuestions = Array.from(questions);
    const [moved] = newQuestions.splice(result.source.index, 1);
    newQuestions.splice(result.destination.index, 0, moved);
    setQuestions(newQuestions);
  };

  // ─────────────────────────────────────────────────────────────
  // Thumbnail logic
  // ─────────────────────────────────────────────────────────────
  const handleThumbnailChange = e => {
    const selectedImage = e.target.files[0];
    if (selectedImage && !selectedImage.type.startsWith('image/')) {
      setError('Only image files are allowed for the thumbnail.');
      setThumbnail(null);
    } else {
      setError('');
      setThumbnail(selectedImage);
    }
  };

  // ─────────────────────────────────────────────────────────────
  // SUBMIT: Save poll to Firestore + optional email
  // ─────────────────────────────────────────────────────────────
  const handleSubmit = async e => {
    e.preventDefault();

    // Basic checks
    if (!auth.currentUser) {
      setError('You must be logged in to create or edit a poll.');
      return;
    }
    if (!title.trim()) {
      setError('Please enter a valid poll title.');
      return;
    }
    if (questions.length === 0) {
      setError('Please add at least one question.');
      return;
    }

    setError('');

    // Build poll data
    const pollData = {
      title,
      questions,
      uid: auth.currentUser.uid,
      displayName: user.displayName || user.email || 'Anonymous',
      expires: expires ? new Date(expires) : null,
      active: true,
      createdAt: pollToEdit ? pollToEdit.createdAt || new Date() : new Date(),
      showResultsAfterSubmit: showResultsAfterVote,
      unit,
      unitName,
      anonymous
    };

    // Handle thumbnail upload if provided
    let thumbnailUrl = '';
    if (thumbnail) {
      setUploading(true);
      try {
        const thumbnailRef = ref(storage, `poll-thumbnails/${thumbnail.name}`);
        const uploadTask = uploadBytesResumable(thumbnailRef, thumbnail);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            snapshot => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            err => {
              console.error('Thumbnail upload error:', err);
              setError('Failed to upload thumbnail. Please try again.');
              setUploading(false);
              reject(err);
            },
            async () => {
              thumbnailUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } catch (error) {
        console.error('Error uploading thumbnail:', error);
        setError('Failed to upload thumbnail. Please try again.');
        setUploading(false);
        return;
      }
    }

    if (thumbnailUrl) {
      pollData.thumbnailUrl = thumbnailUrl;
    }

    // Save poll to Firestore
    try {
      if (pollToEdit) {
        await updateDoc(doc(db, 'polls', pollToEdit.id), pollData);
      } else {
        await addDoc(collection(db, 'polls'), pollData);
      }
    } catch (saveError) {
      console.error('Error saving poll:', saveError);
      setError('Failed to save poll. Please try again.');
      setUploading(false);
      return;
    }

    // ─────────────────────────────────────────────────────────────
    // LOGIC: send emails based on the toggles
    // ─────────────────────────────────────────────────────────────
    try {
      const recipientsSet = new Set();

      // If the admin selected "sendEmailAll", gather all user emails
      if (sendEmailAll) {
        const allUserEmails = await getAllUserEmails(db);
        allUserEmails.forEach(email => recipientsSet.add(email));
      }

      // If the admin selected "sendEmailUnit", gather emails for that unit
      if (sendEmailUnit && unit) {
        // e.g. unit could be "general" or "someUnitId"
        const unitUserEmails = await getUserEmailsByUnit(db, unit);
        unitUserEmails.forEach(email => recipientsSet.add(email));
      }

      // If we have any recipients, send the mass email
      if (recipientsSet.size > 0) {
        const subject = `Nova anketa: ${title}`;
        const html = `
          <h2>Pozdravljeni član ali članica sindikata KSZS!</h2>
          <p>Nova sindikalna anketa z naslovom <strong>${title}</strong> je bila objavljena na našem sindikalnem portalu <a href="https://portal.kszs.si/polls">https://portal.kszs.si/ankete</a>.</p>
          <p>Vabljeni k izpolnitvi ankete, saj je vsako mnenje člana zelo pomembno.</p>
          <p>Lep pozdrav,<br/>Konferenca sindikatov zavarovalništva Slovenije</p>
        `;
        await sendMassEmail(subject, html, Array.from(recipientsSet));
      }
      // If both toggles are OFF => no email is sent.

    } catch (emailError) {
      console.error('Error sending email notifications:', emailError);
    }

    // Reset the form
    setTitle('');
    setQuestions([]);
    setExpires('');
    setThumbnail(null);
    setUploading(false);
    setUploadProgress(0);

    // Reset toggles
    setSendEmailAll(false);
    setSendEmailUnit(false);

    // Notify parent
    onPollCreated();
  };

  // ─────────────────────────────────────────────────────────────
  // Rendering
  // ─────────────────────────────────────────────────────────────
  return (
    <Container className="poll-create-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Naslov ankete</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vnesite naslov ankete"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Enota</Form.Label>
              <Form.Control as="select" value={unit} onChange={handleUnitChange}>
                <option value="general">Splošno</option>
                {units.map(u => (
                  <option key={u.id} value={u.id}>
                    {u.unitName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* Thumbnail Upload */}
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Ikona ankete (thumbnail)</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleThumbnailChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Questions (Drag & Drop) */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="questions">
            {provided => (
              <Accordion ref={provided.innerRef} {...provided.droppableProps}>
                {questions.map((q, qIndex) => (
                  <Draggable
                    key={`question-${qIndex}`}
                    draggableId={`question-${qIndex}`}
                    index={qIndex}
                  >
                    {(dragProvided) => (
                      <Card
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        className="mb-3"
                      >
                        <Accordion.Header>Vprašanje {qIndex + 1}</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group>
                            <Form.Label>Vprašanje</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Vnesite vprašanje"
                              value={q.question}
                              onChange={e =>
                                handleQuestionChange(qIndex, 'question', e.target.value)
                              }
                            />
                          </Form.Group>

                          <Form.Group className="mt-2">
                            <Form.Label>Tip vprašanja</Form.Label>
                            <Form.Control
                              as="select"
                              value={q.type}
                              onChange={e =>
                                handleQuestionChange(qIndex, 'type', e.target.value)
                              }
                            >
                              <option value="radio">En odgovor (Radio)</option>
                              <option value="checkbox">Več odgovorov (Checkbox)</option>
                              <option value="text">Tekst</option>
                            </Form.Control>
                          </Form.Group>

                          {/* If not "text", render options */}
                          {q.type !== 'text' &&
                            q.options.map((option, oIndex) => (
                              <InputGroup key={oIndex} className="mb-3 mt-2">
                                <FormControl
                                  placeholder={`Možnost ${oIndex + 1}`}
                                  value={option}
                                  onChange={e =>
                                    handleOptionChange(qIndex, oIndex, e.target.value)
                                  }
                                />
                              </InputGroup>
                            ))}

                          {q.type !== 'text' && (
                            <Button
                              onClick={() => addOption(qIndex)}
                              variant="secondary"
                              className="mb-3"
                            >
                              Dodaj opcijo
                            </Button>
                          )}

                          <Form.Group className="mt-2">
                            <Form.Check
                              type="checkbox"
                              label="Dovoli lastni odgovor (custom)"
                              checked={q.allowCustomAnswer}
                              onChange={e =>
                                handleQuestionChange(
                                  qIndex,
                                  'allowCustomAnswer',
                                  e.target.checked
                                )
                              }
                            />
                          </Form.Group>

                          <Button
                            variant="danger"
                            onClick={() => removeQuestion(qIndex)}
                            className="mt-3"
                          >
                            Odstrani vprašanje
                          </Button>
                        </Accordion.Body>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Accordion>
            )}
          </Droppable>
        </DragDropContext>

        <Button onClick={addQuestion} variant="secondary" className="mb-3">
          Dodaj vprašanje
        </Button>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Poteče</Form.Label>
              <Form.Control
                type="datetime-local"
                onKeyDown={e => e.preventDefault()}
                value={expires}
                onChange={e => setExpires(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Pokaži rezultate po glasovanju"
            checked={showResultsAfterVote}
            onChange={e => setShowResultsAfterVote(e.target.checked)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="Anonimna anketa"
            checked={anonymous}
            onChange={e => setAnonymous(e.target.checked)}
          />
        </Form.Group>

        {/* NEW: Switches for emailing */}
        <Row className="mb-3">
          <Col>
            <Form.Check
              type="switch"
              id="sendEmailAllSwitch"
              label="Pošlji email vsem uporabnikom"
              checked={sendEmailAll}
              onChange={(e) => setSendEmailAll(e.target.checked)}
            />
          </Col>
          <Col>
            <Form.Check
              type="switch"
              id="sendEmailUnitSwitch"
              label="Pošlji email samo uporabnikom iz te enote"
              checked={sendEmailUnit}
              onChange={(e) => setSendEmailUnit(e.target.checked)}
            />
          </Col>
        </Row>

        {/* Show progress bar while uploading */}
        {uploading && (
          <ProgressBar
            now={uploadProgress}
            label={`${Math.round(uploadProgress)}%`}
            striped
            className="mb-3"
          />
        )}

        <Button
          variant="danger"
          type="submit"
          className="mb-3"
          disabled={uploading}
        >
          {pollToEdit ? 'Posodobi anketo' : 'Ustvari anketo'}
        </Button>
      </Form>
    </Container>
  );
}

export default PollCreateForm;
