import React, { useState, useEffect } from 'react';
import { Button, Form, Alert, Spinner, Card, ListGroup } from 'react-bootstrap';
import { db, auth } from '../../firebase/firebase-config';
import {
  doc,
  collection,
  addDoc,
  getDocs
} from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import { useUser } from '../../context/UserContext';
import * as XLSX from 'xlsx';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function PollDetails({ poll, isAdmin, onEditPoll, onBackToList }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [voters, setVoters] = useState([]);
  const [answersDocs, setAnswersDocs] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [userHasVoted, setUserHasVoted] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user } = useUser();

  // We'll consider "main admin" as user.role === 'admin'
  const isMainAdmin = user?.role === 'admin';

  /**
   * If poll.showResultsAfterSubmit = true => everyone sees results;
   * otherwise (false) => only main admin sees results.
   */
  const canViewResults = poll.showResultsAfterSubmit || isMainAdmin;

  /**
   * Fetch existing answers from Firestore => aggregate into
   * voteCounts (radio/checkbox) and textAnswers (free text).
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      const pollRef = doc(db, 'polls', poll.id);
      const answersRef = collection(pollRef, 'answers');
      const querySnapshot = await getDocs(answersRef);

      const voteCounts = {};   // { [questionIndex]: { [optionValue]: number } }
      const freeTextMap = {};  // { [questionIndex]: string[] }
      const votersList = [];
      const docsData = [];

      let voted = false;

      querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        docsData.push(data);

        // Check if current user has voted
        if (data.userId === auth.currentUser.uid) {
          voted = true;
        }

        // Build a name for who voted
        let fName = data.firstName || '';
        let lName = data.lastName || '';
        let voterName = data.displayName || 'Anonymous';

        if (!poll.anonymous && (fName.trim() || lName.trim())) {
          voterName = `${fName} ${lName}`.trim();
        }

        votersList.push({
          displayName: voterName,
          userId: poll.anonymous ? null : data.userId
        });

        // Aggregate answers
        data.answers.forEach((answerObj) => {
          const ansVal = answerObj.answer;
          // Rely on questionType in Firestore (fallback to poll definition if missing)
          const qType = answerObj.questionType
            || poll.questions[answerObj.questionIndex]?.type
            || 'radio';
          const qIndex = answerObj.questionIndex || 0;

          if (qType === 'text') {
            if (!freeTextMap[qIndex]) {
              freeTextMap[qIndex] = [];
            }
            if (Array.isArray(ansVal)) {
              ansVal.forEach((val) => {
                if (val.trim()) {
                  freeTextMap[qIndex].push(val);
                }
              });
            } else {
              if (ansVal.trim()) {
                freeTextMap[qIndex].push(ansVal);
              }
            }
          } else {
            if (!voteCounts[qIndex]) {
              voteCounts[qIndex] = {};
            }
            if (Array.isArray(ansVal)) {
              ansVal.forEach((val) => {
                voteCounts[qIndex][val] =
                  (voteCounts[qIndex][val] || 0) + 1;
              });
            } else {
              voteCounts[qIndex][ansVal] =
                (voteCounts[qIndex][ansVal] || 0) + 1;
            }
          }
        });
      });

      setResults(voteCounts);
      setTextAnswers(freeTextMap);
      setVoters(votersList);
      setUserHasVoted(voted);
      setAnswersDocs(docsData);
    } catch (error) {
      console.error('Error fetching poll data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poll.id]);

  /**
   * Build & export Excel file with:
   *  - "Rezultati" for radio/checkbox
   *  - "TekstovniOdgovori" for text
   *  - "SeznamGlasovalcev" for everyone (if you want to track who voted)
   *  - "Podrobnosti" only if poll not anonymous (user → question → answer)
   */
  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // 1) Rezultati (radio/checkbox) - vertical layout
    let radioCheckboxRows = [];
    poll.questions
      .filter((q) => q.type !== 'text')
      .forEach((question, qIndex) => {
        radioCheckboxRows.push({
          Vprasanje: question.question,
          Moznost: '',
          Glasovi: ''
        });

        const questionResults = results[qIndex] || {};
        // Merge official options with any found in questionResults
        const allOptions = new Set([
          ...(question.options || []),
          ...Object.keys(questionResults)
        ]);

        allOptions.forEach((opt) => {
          const trimmedOpt = (opt || '').trim();
          if (!trimmedOpt) return;
          radioCheckboxRows.push({
            Vprasanje: '',
            Moznost: trimmedOpt,
            Glasovi: questionResults[trimmedOpt] || 0
          });
        });
        // optional blank row for spacing
        radioCheckboxRows.push({ Vprasanje: '', Moznost: '', Glasovi: '' });
      });

    if (radioCheckboxRows.length > 0) {
      const ws1 = XLSX.utils.json_to_sheet(radioCheckboxRows);
      XLSX.utils.book_append_sheet(workbook, ws1, 'Rezultati');
    }

    // 2) TekstovniOdgovori (for text questions)
    //    IMPORTANT: use the real index so we can match textAnswers[realIndex]
    let textRows = [];
    poll.questions.forEach((question, realIndex) => {
      if (question.type === 'text') {
        const theseTexts = textAnswers[realIndex] || [];
        if (theseTexts.length === 0) {
          textRows.push({
            Vprasanje: question.question,
            Odgovor: ''
          });
        } else {
          theseTexts.forEach((txt) => {
            textRows.push({
              Vprasanje: question.question,
              Odgovor: txt
            });
          });
        }
        // optional spacing
        textRows.push({ Vprasanje: '', Odgovor: '' });
      }
    });

    if (textRows.length > 0) {
      const ws2 = XLSX.utils.json_to_sheet(textRows);
      XLSX.utils.book_append_sheet(workbook, ws2, 'TekstovniOdgovori');
    }

    // 3) SeznamGlasovalcev (even if poll is anonymous, 
    //    if you want an internal record of who voted)
    let votersData = [];
    answersDocs.forEach((ansDoc) => {
      const firstName = ansDoc.firstName || '';
      const lastName = ansDoc.lastName || '';
      const fallback = ansDoc.displayName || 'Anonymous';
      const realName =
        (firstName.trim() || lastName.trim())
          ? `${firstName} ${lastName}`.trim()
          : fallback;
      votersData.push({ Ime: realName });
    });

    if (votersData.length > 0) {
      const wsVoters = XLSX.utils.json_to_sheet(votersData);
      XLSX.utils.book_append_sheet(workbook, wsVoters, 'SeznamGlasovalcev');
    }

    // 4) Podrobnosti => only if not anonymous
    if (!poll.anonymous) {
      let detailedAnswers = [];
      answersDocs.forEach((ansDoc) => {
        const fName = ansDoc.firstName || '';
        const lName = ansDoc.lastName || '';
        const fallback = ansDoc.displayName || 'Anonymous';
        const voterName =
          (fName.trim() || lName.trim())
            ? `${fName} ${lName}`.trim()
            : fallback;
        ansDoc.answers.forEach((answerItem) => {
          const questionText =
            poll.questions[answerItem.questionIndex]?.question || '???';
          const answerVal = Array.isArray(answerItem.answer)
            ? answerItem.answer.join(', ')
            : answerItem.answer;
          detailedAnswers.push({
            Voter: voterName,
            Question: questionText,
            Answer: answerVal
          });
        });
      });

      if (detailedAnswers.length > 0) {
        const wsDetails = XLSX.utils.json_to_sheet(detailedAnswers);
        XLSX.utils.book_append_sheet(workbook, wsDetails, 'Podrobnosti');
      }
    }

    // Save the file
    XLSX.writeFile(
      workbook,
      `Anketa_${poll.title.replace(/\s+/g, '_')}_Podatki.xlsx`
    );
  };

  /**
   * Submit user answers:
   *  - For each question => store questionIndex + questionType + answer
   */
  const handleSubmit = async () => {
    // If user left any question unanswered, block
    if (Object.keys(answers).length < poll.questions.length) {
      alert('Prosimo odgovorite na vsa vprašanja.');
      return;
    }

    if (!userHasVoted && poll.active) {
      setLoading(true);
      const pollRef = doc(db, 'polls', poll.id);
      const answersRef = collection(pollRef, 'answers');

      // Store questionIndex and questionType
      const voteData = {
        answers: Object.entries(answers).map(([index, answer]) => {
          const questionIndex = Number(index);
          const questionType = poll.questions[questionIndex]?.type || 'radio';
          return {
            questionIndex,
            questionType,
            answer
          };
        }),
        createdAt: new Date(),
        userId: auth.currentUser.uid,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        displayName: user.displayName || 'Anonymous'
      };

      try {
        await addDoc(answersRef, voteData);
        setUserHasVoted(true);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
        fetchData(); // Refresh data
      } catch (error) {
        console.error('Error submitting vote:', error);
        alert('Glasovanje ni uspelo. Poskusite znova.');
      }
      setLoading(false);
    }
  };

  // Pagination logic: show one question at a time
  const currentQuestion = poll.questions[currentPage] || {};

  const handleAnswerChange = (index, value) => {
    setAnswers({ ...answers, [index]: value });
  };

  const handleCheckboxChange = (index, value, checked) => {
    const currentAnswers = answers[index] || [];
    if (checked) {
      handleAnswerChange(index, [...currentAnswers, value]);
    } else {
      handleAnswerChange(
        index,
        currentAnswers.filter((a) => a !== value)
      );
    }
  };

  /**
   * Render a single question's form controls
   */
  const renderQuestion = (question, index) => {
    switch (question.type) {
      case 'radio':
        return (
          <>
            {question.options.map((option, oIndex) => (
              <Form.Check
                type="radio"
                label={option}
                name={`question-${index}`}
                key={oIndex}
                checked={answers[index] === option}
                onChange={() => handleAnswerChange(index, option)}
                disabled={userHasVoted || !poll.active}
              />
            ))}
            {question.allowCustomAnswer && (
              <Form.Control
                type="text"
                placeholder="Drugo:"
                value={
                  answers[index] &&
                  !question.options.includes(answers[index])
                    ? answers[index]
                    : ''
                }
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                disabled={userHasVoted || !poll.active}
              />
            )}
          </>
        );

      case 'checkbox':
        return (
          <>
            {question.options.map((option, oIndex) => (
              <Form.Check
                type="checkbox"
                label={option}
                name={`question-${index}`}
                key={oIndex}
                checked={answers[index]?.includes(option)}
                onChange={(e) =>
                  handleCheckboxChange(index, option, e.target.checked)
                }
                disabled={userHasVoted || !poll.active}
              />
            ))}
            {question.allowCustomAnswer && (
              <Form.Control
                type="text"
                placeholder="Drugo:"
                onChange={(e) => {
                  const currentAnswers = answers[index] || [];
                  const officialOptions = question.options;
                  const newCustom = e.target.value;
                  // remove old custom if it wasn't among official options
                  const filtered = currentAnswers.filter((a) =>
                    officialOptions.includes(a)
                  );
                  handleAnswerChange(index, [...filtered, newCustom]);
                }}
                disabled={userHasVoted || !poll.active}
              />
            )}
          </>
        );

      case 'text':
        return (
          <Form.Control
            type="text"
            placeholder="Vaš odgovor"
            value={answers[index] || ''}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
            disabled={userHasVoted || !poll.active}
          />
        );

      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: 200 }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Nalagam...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      {/* Poll Title & Voting UI */}
      <Card className="poll-card">
        <Card.Body>
          <h4 className="mb-5">{poll.title}</h4>

          {showAlert && (
            <Alert variant="info" className="mt-2">
              Hvala za oddan glas!
            </Alert>
          )}

          {/* Show form if not voted and poll is active */}
          {!userHasVoted && poll.active && (
            <Form>
              <h5>{currentQuestion.question}</h5>
              {renderQuestion(currentQuestion, currentPage)}

              <div className="d-flex justify-content-between mt-3">
                <Button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 0}
                >
                  Nazaj
                </Button>
                {currentPage < poll.questions.length - 1 ? (
                  <Button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={!answers[currentPage]}
                  >
                    Naprej
                  </Button>
                ) : (
                  <Button
                    onClick={handleSubmit}
                    disabled={!answers[currentPage]}
                  >
                    Potrdi
                  </Button>
                )}
              </div>
            </Form>
          )}

          {(userHasVoted || !poll.active) && (
            <Alert variant="info" className="mt-2">
              Hvala za oddan glas!
            </Alert>
          )}
        </Card.Body>
      </Card>

      {/* If user can see results => show them */}
      {canViewResults && (
        <>
          <Card className="poll-card my-5">
            <Card.Body>
              <h5>Rezultati ankete</h5>

              {poll.questions.map((question, qIndex) => {
                if (question.type === 'text') {
                  // Show text answers
                  const theseTexts = textAnswers[qIndex] || [];
                  return (
                    <div key={qIndex} style={{ marginBottom: '20px' }}>
                      <h5>{question.question}</h5>
                      {theseTexts.length === 0 ? (
                        <p>
                          <em>Ni odgovorov.</em>
                        </p>
                      ) : (
                        <ListGroup>
                          {theseTexts.map((txt, i) => (
                            <ListGroup.Item key={i}>{txt}</ListGroup.Item>
                          ))}
                        </ListGroup>
                      )}
                    </div>
                  );
                } else {
                  // Radio/checkbox => show aggregated data
                  const questionResults = results[qIndex] || {};
                  const labels = Object.keys(questionResults);
                  const data = Object.values(questionResults);

                  return (
                    <div key={qIndex} style={{ marginBottom: '20px' }}>
                      <h5>{question.question}</h5>
                      {labels.length === 0 ? (
                        <p>
                          <em>Ni odgovorov.</em>
                        </p>
                      ) : (
                        <div style={{ height: '200px', overflowY: 'auto' }}>
                          <Bar
                            data={{
                              labels,
                              datasets: [
                                {
                                  label: 'Št. glasov',
                                  data,
                                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                  borderColor: 'rgba(53, 162, 235, 1)',
                                  borderWidth: 1
                                }
                              ]
                            }}
                            options={{
                              indexAxis: 'y',
                              scales: { x: { beginAtZero: true } },
                              maintainAspectRatio: false
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </Card.Body>
          </Card>

          {/* If poll is NOT anonymous => show voter list in UI */}
          {!poll.anonymous && (
            <Card className="poll-card mb-5">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5>Seznam glasovalcev</h5>
                  <Button variant="primary" onClick={handleExportToExcel}>
                    Izvozi rezultate v Excel
                  </Button>
                </div>
                <ListGroup variant="flush">
                  {voters.length > 0 ? (
                    voters.map((voter, index) => (
                      <ListGroup.Item key={index}>
                        {voter.displayName}
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>Ni glasovalcev.</ListGroup.Item>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
          )}

          {/* If poll is anonymous => hide voter list in UI */}
          {poll.anonymous && (
            <Card className="poll-card mb-5">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5>Anonimna anketa</h5>
                  <Button variant="primary" onClick={handleExportToExcel}>
                    Izvozi rezultate v Excel
                  </Button>
                </div>
                <p>Glasovalci niso prikazani, saj je anketa anonimna.</p>
              </Card.Body>
            </Card>
          )}
        </>
      )}

      {/* If user cannot view results => show a warning */}
      {!canViewResults && (
        <div className="mt-5">
          <h5>Rezultati ankete</h5>
          <Alert variant="warning">
            Rezultati te ankete niso javno dostopni.
          </Alert>
          <Button variant="secondary" onClick={onBackToList}>
            Nazaj
          </Button>
        </div>
      )}
    </>
  );
}

export default PollDetails;
