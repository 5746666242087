import React, { useEffect, useState } from 'react';
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  deleteDoc,
  where,
  limit,
  startAfter,
  updateDoc,
  getDocs
} from 'firebase/firestore';
import { ListGroup, Badge, Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { FaTrash, FaEdit, FaSort } from 'react-icons/fa';
import './QnAList.css';
import { db, auth } from '../../firebase/firebase-config';
import QnAQuestion from '../../models/QnAQuestion';
import { useUser } from '../../context/UserContext';

const QNA_LIMIT = 20;

function QnAList({ onSelectQuestion, isAdmin, onEditQuestion }) {
  const [questions, setQuestions] = useState([]);
  const [unreadStatus, setUnreadStatus] = useState({});
  const [lastVisible, setLastVisible] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  // For reorder modal
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [movePosition, setMovePosition] = useState('');

  const { user, loading } = useUser();

  // ─────────────────────────────────────────────────────────────
  // 1) Listen to QnA in ascending order by "order"
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (loading || !user) return;

    let baseQuery;
    if (user.role === 'admin') {
      baseQuery = query(
        collection(db, 'questions'),
        orderBy('order', 'asc'),
        limit(QNA_LIMIT)
      );
    } else {
      baseQuery = query(
        collection(db, 'questions'),
        where('unit', 'in', ['general', user.unit]),
        orderBy('order', 'asc'),
        limit(QNA_LIMIT)
      );
    }

    const unsubscribe = onSnapshot(baseQuery, (querySnapshot) => {
      const questionsList = querySnapshot.docs.map((docSnap) =>
        QnAQuestion.fromFirestore(docSnap)
      );
      setQuestions(questionsList);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
    });

    return () => unsubscribe();
  }, [user, loading]);

  // ─────────────────────────────────────────────────────────────
  // 2) Load more questions
  // ─────────────────────────────────────────────────────────────
  const loadMoreQuestions = async () => {
    // If we have no more pages or are already loading, do nothing
    if (!lastVisible || loadingMore) return;

    setLoadingMore(true);

    let baseQuery;
    if (user.role === 'admin') {
      baseQuery = query(
        collection(db, 'questions'),
        orderBy('order', 'asc'),
        startAfter(lastVisible),
        limit(QNA_LIMIT)
      );
    } else {
      baseQuery = query(
        collection(db, 'questions'),
        where('unit', 'in', ['general', user.unit]),
        orderBy('order', 'asc'),
        startAfter(lastVisible),
        limit(QNA_LIMIT)
      );
    }

    const querySnapshot = await getDocs(baseQuery);
    const moreQuestions = querySnapshot.docs.map((docSnap) =>
      QnAQuestion.fromFirestore(docSnap)
    );

    setQuestions((prev) => [...prev, ...moreQuestions]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
    setLoadingMore(false);
  };

  // ─────────────────────────────────────────────────────────────
  // 3) Track unread status
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (loading || !user) return;

    const checkUnreadStatus = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data()?.lastRead?.qna || {};

      const allQuestionsQuery = collection(db, 'questions');
      const unsubscribe = onSnapshot(allQuestionsQuery, (snapshot) => {
        const tempStatus = {};
        snapshot.docs.forEach((docSnap) => {
          const questionId = docSnap.id;
          const data = docSnap.data();
          if (!data.createdAt) return;
          const createdAtMillis = data.createdAt.toMillis
            ? data.createdAt.toMillis()
            : 0;
          const lastReadTimestamp = lastReadTimestamps[questionId]?.toMillis
            ? lastReadTimestamps[questionId].toMillis()
            : 0;
          tempStatus[questionId] = createdAtMillis > lastReadTimestamp;
        });
        setUnreadStatus(tempStatus);
      });

      return () => unsubscribe();
    };

    checkUnreadStatus();
  }, [loading, user]);

  // ─────────────────────────────────────────────────────────────
  // 4) Delete question
  // ─────────────────────────────────────────────────────────────
  const handleDelete = async (questionId) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      await deleteDoc(doc(db, 'questions', questionId));
    }
  };

  // ─────────────────────────────────────────────────────────────
  // 5) Reordering logic (admin only)
  // ─────────────────────────────────────────────────────────────
  const handleReorderClick = (question) => {
    setSelectedQuestion(question);
    setShowReorderModal(true);
  };

  const handleMoveUp = async () => {
    if (!selectedQuestion) return;
    const index = questions.findIndex((q) => q.id === selectedQuestion.id);
    if (index > 0) {
      const newOrder = questions[index - 1].order;
      await updateDoc(doc(db, 'questions', selectedQuestion.id), {
        order: newOrder - 1
      });
    }
    setShowReorderModal(false);
  };

  const handleMoveDown = async () => {
    if (!selectedQuestion) return;
    const index = questions.findIndex((q) => q.id === selectedQuestion.id);
    if (index < questions.length - 1) {
      const newOrder = questions[index + 1].order;
      await updateDoc(doc(db, 'questions', selectedQuestion.id), {
        order: newOrder + 1
      });
    }
    setShowReorderModal(false);
  };

  const handleMoveToPosition = async () => {
    if (!selectedQuestion) return;
    const newPos = parseInt(movePosition, 10);
    if (isNaN(newPos) || newPos < 1 || newPos > questions.length) {
      alert('Vnesite veljavno pozicijo.');
      return;
    }
    const newOrder = questions[newPos - 1].order + 1;
    await updateDoc(doc(db, 'questions', selectedQuestion.id), {
      order: newOrder
    });
    setShowReorderModal(false);
  };

  // ─────────────────────────────────────────────────────────────
  // 6) onScroll event (for infinite scroll)
  // ─────────────────────────────────────────────────────────────
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    // You can adjust the offset (e.g., 50 px) so it triggers a bit earlier
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      loadMoreQuestions();
    }
  };

  // ─────────────────────────────────────────────────────────────
  // Rendering
  // ─────────────────────────────────────────────────────────────
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!questions.length) {
    return <div>No questions available</div>;
  }

  return (
    <>
      <div
        className="qna-list-container"
        style={{ maxHeight: '80vh', overflowY: 'auto', paddingBottom: '20px'}}
        onScroll={handleScroll}
      >
        <ListGroup className="qna-list">
          {questions.map((question) => {
            const canEdit = isAdmin || (user && user.uid === question.uid);

            return (
              <ListGroup.Item
                key={question.id}
                className="qna-list-item"
                onClick={() => onSelectQuestion(question)}
              >
                <Row>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{question.title}</div>
                      <small>Ustvaril {question.displayName}</small>
                    </div>
                    <Badge bg="info" className="ms-2">
                      {question.unit === 'general' ? 'Splošno' : question.unitName}
                    </Badge>
                    <small className="text-muted text-end ms-2">
                      {question.createdAt.toLocaleString()}
                    </small>
                    {unreadStatus[question.id] && (
                      <Badge bg="danger" className="ms-2" pill>
                        &#8226;
                      </Badge>
                    )}
                  </Col>
                </Row>

                {canEdit && (
                  <Row>
                    <Col md={12} className="d-flex ms-2 my-2">
                      {isAdmin && (
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="me-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReorderClick(question);
                          }}
                        >
                          <FaSort /> Uredi vrstni red
                        </Button>
                      )}

                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditQuestion(question);
                        }}
                      >
                        <FaEdit /> Uredi
                      </Button>

                      {isAdmin && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(question.id);
                          }}
                        >
                          <FaTrash /> Izbriši
                        </Button>
                      )}
                    </Col>
                  </Row>
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>

        {/* 
          Optional: "Load More" button at the bottom 
          Only show it if there's a 'lastVisible' doc (means more data).
        */}
        {lastVisible && (
          <div className="d-flex justify-content-center my-3">
            <Button
              onClick={loadMoreQuestions}
              disabled={loadingMore}
              variant="secondary"
            >
              {loadingMore ? 'Nalagam...' : 'Naloži več'}
            </Button>
          </div>
        )}
      </div>

      {/* Reorder Modal */}
      <Modal show={showReorderModal} onHide={() => setShowReorderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reorder Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" className="mb-2" onClick={handleMoveUp}>
            Premakni gor
          </Button>
          <Button variant="secondary" className="mb-2 ms-2" onClick={handleMoveDown}>
            Premakni dol
          </Button>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>
                Premakni na pozicijo (1-{questions.length}):
              </Form.Label>
              <Form.Control
                type="number"
                min="1"
                max={questions.length}
                value={movePosition}
                onChange={(e) => setMovePosition(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleMoveToPosition}>
              Potrdi premik
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

QnAList.defaultProps = {
  onEditQuestion: () => {}
};

export default QnAList;
