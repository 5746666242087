// Chat.js (partial snippet)
import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase/firebase-config';

import UnitSelector from './UnitSelector';
import ChatRoom from './ChatRoom';
import ChatRoomManagement from './ChatRoomManagement'; // if separate file
import './Chat.css';

function Chat() {
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showRoomManagement, setShowRoomManagement] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setIsAdmin(userDocSnap.data().role === 'admin');
        }
      }
    };
    checkAdmin();
  }, []);

  const handleSelectUnit = (unit) => {
    setSelectedUnit(unit);
  };

  const handleBack = () => {
    setSelectedUnit(null);
  };

  return (
    <div className="full-bg">
      <Container className="chat-container mt-3">
        <Row className="justify-content-between align-items-center mb-3">
          <Col>
            <h1 className="chat-title">Klepetalnica</h1>
          </Col>
          <Col className="text-end">
            {isAdmin && (
              <Button variant="primary" onClick={() => setShowRoomManagement(true)}>
                Upravljanje klepetalnic
              </Button>
            )}{' '}
            {selectedUnit && (
              <Button variant="secondary" onClick={handleBack}>
                Nazaj na izbiro
              </Button>
            )}
          </Col>
        </Row>

        {selectedUnit ? (
          <ChatRoom
            unitId={selectedUnit.id}
            // If it’s a chatRoom, use .roomName; if it’s a unit, use .unitName
            unitName={selectedUnit.roomName ? selectedUnit.roomName : selectedUnit.unitName}
          />
        ) : (
          <UnitSelector onSelectUnit={handleSelectUnit} />
        )}
      </Container>

      {showRoomManagement && (
        <ChatRoomManagement onClose={() => setShowRoomManagement(false)} />
      )}
    </div>
  );
}

export default Chat;
