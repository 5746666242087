// QnAQuestion.js
class QnAQuestion {
  constructor({
    id,
    title,
    uid,
    displayName,
    createdAt,
    unit,
    unitName,
    order
  }) {
    this.id = id || null;
    this.title = title || "";
    this.uid = uid || null;
    this.displayName = displayName || "Anonymous";
    this.createdAt = createdAt || new Date();
    this.unit = unit || "general";
    this.unitName = unitName || "Splošno";
    this.order = order || 0; // NEW: store the "order" for sorting
  }

  toFirestore() {
    return {
      title: this.title,
      uid: this.uid,
      displayName: this.displayName,
      createdAt: this.createdAt,
      unit: this.unit,
      unitName: this.unitName,
      order: this.order // include order
    };
  }

  static fromFirestore(snapshot) {
    const data = snapshot.data();
    return new QnAQuestion({
      id: snapshot.id,
      title: data.title,
      uid: data.uid,
      displayName: data.displayName,
      createdAt: data.createdAt ? data.createdAt.toDate() : null,
      unit: data.unit || "general",
      unitName: data.unitName || "Splošno",
      order: data.order || 0 // parse order
    });
  }
}

export default QnAQuestion;
