// ChatRoomManagement.js (or inline within Chat.js if you prefer)
import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase-config';
import { Modal, Button, Form, Table } from 'react-bootstrap';

function ChatRoomManagement({ onClose }) {
  const [chatRooms, setChatRooms] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  // Fields for creating a new room
  const [roomName, setRoomName] = useState('');
  const [roomDescription, setRoomDescription] = useState('');
  const [newRoomAllowedUsers, setNewRoomAllowedUsers] = useState([]);

  // Editing an existing room
  const [editingRoom, setEditingRoom] = useState(null);

  useEffect(() => {
    fetchChatRooms();
    fetchAllUsers();
  }, []);

  const fetchChatRooms = async () => {
    const snapshot = await getDocs(collection(db, 'chatRooms'));
    const roomsData = snapshot.docs.map(docItem => ({
      id: docItem.id,
      ...docItem.data()
    }));
    setChatRooms(roomsData);
  };

  const fetchAllUsers = async () => {
    const snapshot = await getDocs(collection(db, 'users'));
    const usersData = snapshot.docs.map(docItem => ({
      id: docItem.id,
      ...docItem.data()
    }));
    setAllUsers(usersData);
  };

  // ─────────────────────────────────────────────────────────────
  // Creating a new room
  // ─────────────────────────────────────────────────────────────
  const handleAddRoom = async () => {
    if (!roomName.trim()) {
      alert('Ime klepetalnice je obvezno.');
      return;
    }
    await addDoc(collection(db, 'chatRooms'), {
      roomName,
      description: roomDescription,
      allowedUsers: newRoomAllowedUsers, // selected via checkboxes
      createdBy: auth.currentUser?.uid || null
    });

    // Reset fields
    setRoomName('');
    setRoomDescription('');
    setNewRoomAllowedUsers([]);

    // Refresh
    fetchChatRooms();
  };

  // For new room checkboxes
  const handleNewRoomUserChange = (uid, isChecked) => {
    if (isChecked) {
      setNewRoomAllowedUsers(prev => [...prev, uid]);
    } else {
      setNewRoomAllowedUsers(prev => prev.filter(item => item !== uid));
    }
  };

  // ─────────────────────────────────────────────────────────────
  // Deleting a room
  // ─────────────────────────────────────────────────────────────
  const handleDeleteRoom = async (roomId) => {
    if (window.confirm('Ali ste prepričani, da želite izbrisati to klepetalnico?')) {
      await deleteDoc(doc(db, 'chatRooms', roomId));
      fetchChatRooms();
    }
  };

  // ─────────────────────────────────────────────────────────────
  // Editing an existing room
  // ─────────────────────────────────────────────────────────────
  const handleEditRoom = (room) => {
    // Copy the room to the state, so we can adjust the "allowedUsers" live
    setEditingRoom({ ...room });
  };

  const handleCancelEdit = () => {
    setEditingRoom(null);
  };

  const handleSaveRoom = async () => {
    if (!editingRoom) return;

    // Prepare new doc data
    const roomDocRef = doc(db, 'chatRooms', editingRoom.id);
    await updateDoc(roomDocRef, {
      roomName: editingRoom.roomName,
      description: editingRoom.description,
      allowedUsers: editingRoom.allowedUsers || []
    });

    setEditingRoom(null);
    fetchChatRooms();
  };

  // Called when checkboxes change for editing an existing room
  const handleEditingRoomUserChange = (uid, isChecked) => {
    if (!editingRoom) return;
    if (isChecked) {
      // Add user to allowedUsers
      setEditingRoom(prev => ({
        ...prev,
        allowedUsers: [...(prev.allowedUsers || []), uid]
      }));
    } else {
      // Remove user
      setEditingRoom(prev => ({
        ...prev,
        allowedUsers: (prev.allowedUsers || []).filter(item => item !== uid)
      }));
    }
  };

  // ─────────────────────────────────────────────────────────────
  // Render
  // ─────────────────────────────────────────────────────────────
  return (
    <Modal show onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upravljanje klepetalnic</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Dodaj novo klepetalnico</h5>
        <Form.Group controlId="formRoomName">
          <Form.Label>Ime klepetalnice</Form.Label>
          <Form.Control
            type="text"
            placeholder="Vnesite ime..."
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formRoomDescription" className="mt-3">
          <Form.Label>Opis klepetalnice</Form.Label>
          <Form.Control
            type="text"
            placeholder="Vnesite opis..."
            value={roomDescription}
            onChange={(e) => setRoomDescription(e.target.value)}
          />
        </Form.Group>

        {/* Checkboxes for all users, for the new room */}
        <div className="mt-3">
          <Form.Label>Dovoli uporabnikom:</Form.Label>
          {allUsers.map((user) => {
            const uid = user.id;
            const checked = newRoomAllowedUsers.includes(uid);
            const displayName = user.displayName || user.email || uid;

            return (
              <Form.Check
                key={uid}
                type="checkbox"
                label={displayName}
                checked={checked}
                onChange={(e) => handleNewRoomUserChange(uid, e.target.checked)}
              />
            );
          })}
        </div>

        <Button variant="primary" className="mt-3" onClick={handleAddRoom}>
          Dodaj klepetalnico
        </Button>

        <hr />
        <h5>Seznam klepetalnic</h5>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Naziv</th>
              <th>Opis</th>
              <th>Dovoljeni uporabniki</th>
              <th>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {chatRooms.map((room) => {
              const isEditing = editingRoom && editingRoom.id === room.id;
              return (
                <tr key={room.id}>
                  {isEditing ? (
                    <>
                      {/* Editing row */}
                      <td>
                        <Form.Control
                          type="text"
                          value={editingRoom.roomName}
                          onChange={(e) =>
                            setEditingRoom(prev => ({ ...prev, roomName: e.target.value }))
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={editingRoom.description || ''}
                          onChange={(e) =>
                            setEditingRoom(prev => ({
                              ...prev,
                              description: e.target.value
                            }))
                          }
                        />
                      </td>
                      <td>
                        {/* Checkboxes for existing room's allowedUsers */}
                        {allUsers.map((user) => {
                          const uid = user.id;
                          const displayName = user.displayName || user.email || uid;
                          const checked = editingRoom.allowedUsers?.includes(uid);
                          return (
                            <Form.Check
                              key={uid}
                              type="checkbox"
                              label={displayName}
                              checked={!!checked}
                              onChange={(e) =>
                                handleEditingRoomUserChange(uid, e.target.checked)
                              }
                            />
                          );
                        })}
                      </td>
                      <td>
                        <Button variant="success" onClick={handleSaveRoom}>
                          Shrani
                        </Button>{' '}
                        <Button variant="secondary" onClick={handleCancelEdit}>
                          Prekliči
                        </Button>
                      </td>
                    </>
                  ) : (
                    <>
                      {/* Normal (non-editing) row */}
                      <td>{room.roomName}</td>
                      <td>{room.description}</td>
                      <td>
                        {/* Show the actual names or emails of the allowed users */}
                        {room.allowedUsers?.map(uid => {
                          const userObj = allUsers.find(u => u.id === uid);
                          return userObj
                            ? (userObj.displayName || userObj.email || uid)
                            : uid;
                        }).join(', ')}
                      </td>
                      <td>
                        <Button variant="primary" onClick={() => handleEditRoom(room)}>
                          Uredi
                        </Button>{' '}
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteRoom(room.id)}
                        >
                          Izbriši
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Zapri
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChatRoomManagement;
