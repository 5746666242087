import React, { useState, Suspense, lazy } from 'react';
import { Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useUser } from '../../context/UserContext';
import './Article.css';

const ArticleCreate = lazy(() => import('./ArticleCreate'));
const ArticleList = lazy(() => import('./ArticleList'));
const ArticleDetail = lazy(() => import('./ArticleDetail'));

function Article() {
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [showCreateArticle, setShowCreateArticle] = useState(false);

    const { user } = useUser();
    const isAdmin = user?.role === 'admin';
    const isLocalAdmin = user?.role === 'local-admin';

    const handleSelectArticle = (article) => {
        setSelectedArticle(article);
        setShowCreateArticle(false);
    };

    const handleBack = () => {
        setSelectedArticle(null);
        setShowCreateArticle(false);
    };

    const toggleCreateArticleForm = () => {
        setShowCreateArticle(!showCreateArticle);
        setSelectedArticle(null);
    };

    const handleArticleCreated = () => {
        setShowCreateArticle(false);
        setSelectedArticle(null);
    };

    return (
        <div className="full-bg">
            <Container className="article-container mt-3">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className="article-title">Obrazci</h1>
                    </Col>
                    <Col className="text-end">
                        {!selectedArticle && (isAdmin || isLocalAdmin) && (
                            <Button variant={showCreateArticle ? "secondary" : "danger"} onClick={toggleCreateArticleForm}>
                                {showCreateArticle ? 'Nazaj na izbiro' : 'Dodaj obrazec'}
                            </Button>
                        )}
                        {selectedArticle && (
                            <Button variant="secondary" onClick={handleBack}>Nazaj na izbiro</Button>
                        )}
                    </Col>
                </Row>
                <Suspense fallback={<Spinner animation="border" />}>
                    {!selectedArticle ? (
                        showCreateArticle ? (
                            <ArticleCreate onArticleCreated={handleArticleCreated} />
                        ) : (
                            <ArticleList onSelectArticle={handleSelectArticle} isAdmin={isAdmin} isLocalAdmin={isLocalAdmin} />
                        )
                    ) : (
                        <ArticleDetail article={selectedArticle} />
                    )}
                </Suspense>
            </Container>
        </div>
    );
}

export default Article;
