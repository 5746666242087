// src/utils/emailUtils.js

import { collection, getDocs, query, where } from 'firebase/firestore';

/**
 * Fetch all user emails from the "users" collection in Firestore.
 *
 * @param {object} db - The Firestore database instance.
 * @returns {Promise<string[]>} Array of email addresses.
 */
export async function getAllUserEmails(db) {
  const snapshot = await getDocs(collection(db, 'users'));
  const emails = [];
  snapshot.forEach(doc => {
    const data = doc.data();
    if (data.email) {
      emails.push(data.email);
    }
  });
  return emails;
}

/**
 * Fetch user emails by unit (e.g., unit === "general" or "someUnitId")
 *
 * @param {object} db - The Firestore database instance
 * @param {string} unitId - The ID of the unit to filter on
 * @returns {Promise<string[]>} Array of email addresses for that unit
 */
export async function getUserEmailsByUnit(db, unitId) {
  const q = query(
    collection(db, 'users'),
    where('unit', '==', unitId)
  );
  const snapshot = await getDocs(q);
  const emails = [];
  snapshot.forEach(doc => {
    const data = doc.data();
    if (data.email) {
      emails.push(data.email);
    }
  });
  return emails;
}

/**
 * Send a mass email by calling the Node/Express backend.
 * The server expects a POST to /api/send-email with:
 *    { subject, html, recipients }
 * We also pass a custom header "x-api-key" matching the server's secret for security.
 *
 * @param {string} subject    - Subject line for the email (e.g. "Nova anketa")
 * @param {string} html       - HTML body of the email
 * @param {string[]} recipients - Array of email addresses
 * @returns {Promise<object>} - { message: string, count: number }
 */
export async function sendMassEmail(subject, html, recipients) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/send-email`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_SECRET_KEY,
        },
        body: JSON.stringify({ subject, html, recipients }),
      }
    );

    if (!response.ok) {
      const errData = await response.json();
      throw new Error(errData.error || 'Failed to send emails');
    }
    return await response.json(); // e.g. { message: 'Emails sent successfully', count: 25 }
  } catch (error) {
    console.error('sendMassEmail error:', error);
    throw error;
  }
}

/**
 * Send a test email by calling the Node/Express backend.
 * The server expects a POST to /api/send-email with:
 *    { subject, html, recipients }
 * We also pass a custom header "x-api-key" matching the server's secret for security.
 *
 * @param {string} subject    - Subject line for the email (e.g. "Test Email")
 * @param {string} html       - HTML body of the email
 * @returns {Promise<object>} - { message: string, count: number }
 */
export async function sendMassEmailTest(subject, html) {
  // Hardcoded test email address
  const testRecipient = 'marko@marvixdigital.com'; // Example test email
  
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/send-email`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_SECRET_KEY,
        },
        body: JSON.stringify({ subject, html, recipients: [testRecipient] }),
      }
    );

    if (!response.ok) {
      const errData = await response.json();
      throw new Error(errData.error || 'Failed to send email');
    }
    
    return await response.json(); // e.g. { message: 'Emails sent successfully', count: 1 }
  } catch (error) {
    console.error('sendMassEmail error:', error);
    throw error;
  }
}
