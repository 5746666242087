// NoticeList.js

import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/firebase-config';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDocs,
  updateDoc,
  deleteDoc,
  where,
  getDoc,
  limit,
  startAfter
} from 'firebase/firestore';
import { ListGroup, Badge, Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { FaEdit, FaTrash, FaSort } from 'react-icons/fa';
import './NoticeList.css';
import { useUser } from '../../context/UserContext';

const NOTICES_LIMIT = 20;

function NoticeList({ onSelectNotice, isAdmin, onEditNotice }) {
  const [notices, setNotices] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const noticesEndRef = useRef(null);
  const noticesContainerRef = useRef(null);
  const [unreadNotices, setUnreadNotices] = useState([]);
  const [unitNames, setUnitNames] = useState({});

  // Reorder modal states
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [movePosition, setMovePosition] = useState('');

  const { user } = useUser();

  // Fetch unit names
  useEffect(() => {
    const fetchUnits = async () => {
      const unitsQuerySnapshot = await getDocs(collection(db, 'units'));
      const unitsData = unitsQuerySnapshot.docs.reduce((acc, unitDoc) => {
        acc[unitDoc.id] = unitDoc.data().unitName;
        return acc;
      }, {});
      setUnitNames(unitsData);
    };

    fetchUnits();
  }, []);

  // Track unread notices
  useEffect(() => {
    const fetchUnreadNotices = async () => {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data()?.lastRead?.notices || {};

      let baseQuery;
      if (user.role === 'admin') {
        baseQuery = query(
          collection(db, 'notices'),
          orderBy('order', 'asc'), // use order field
          limit(NOTICES_LIMIT)
        );
      } else {
        // Restrict to 'general' or the user's unit
        baseQuery = query(
          collection(db, 'notices'),
          where('unit', 'in', ['general', user.unit]),
          orderBy('order', 'asc'),
          limit(NOTICES_LIMIT)
        );
      }

      const unsubscribe = onSnapshot(baseQuery, (querySnapshot) => {
        const newUnreadNotices = [];
        const noticesData = querySnapshot.docs.map((docSnap) => {
          const data = docSnap.data();
          // createdAt might be used for display, but we rely on 'order' for ordering
          const createdAtMillis = data.createdAt ? data.createdAt.toMillis() : 0;

          // Determine if it's unread
          if (
            !lastReadTimestamps[docSnap.id] ||
            lastReadTimestamps[docSnap.id].toMillis() < createdAtMillis
          ) {
            newUnreadNotices.push(docSnap.id);
          }

          return {
            id: docSnap.id,
            ...data,
            createdAt: createdAtMillis
          };
        });

        setNotices(noticesData);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
        setUnreadNotices(newUnreadNotices);
      });

      return () => unsubscribe();
    };

    if (user) {
      fetchUnreadNotices();
    }
  }, [user]);

  // Load more notices on scroll
  const loadMoreNotices = async () => {
    if (!lastVisible || loadingMore) return;
    setLoadingMore(true);

    let nextQuery;
    if (user && user.role === 'admin') {
      nextQuery = query(
        collection(db, 'notices'),
        orderBy('order', 'asc'),
        startAfter(lastVisible),
        limit(NOTICES_LIMIT)
      );
    } else {
      nextQuery = query(
        collection(db, 'notices'),
        where('unit', 'in', ['general', user.unit]),
        orderBy('order', 'asc'),
        startAfter(lastVisible),
        limit(NOTICES_LIMIT)
      );
    }

    const querySnapshot = await getDocs(nextQuery);
    const moreNotices = querySnapshot.docs.map((docSnap) => ({
      id: docSnap.id,
      ...docSnap.data(),
      createdAt: docSnap.data().createdAt?.toMillis() || 0
    }));

    setNotices((prev) => [...prev, ...moreNotices]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
    setLoadingMore(false);
  };

  const handleScroll = () => {
    if (
      noticesContainerRef.current.scrollTop +
        noticesContainerRef.current.clientHeight >=
      noticesContainerRef.current.scrollHeight
    ) {
      loadMoreNotices();
    }
  };

  const handleDelete = async (noticeId) => {
    if (window.confirm('Are you sure you want to delete this notice?')) {
      await deleteDoc(doc(db, 'notices', noticeId));
    }
  };

  // Reordering logic (admin only)
  const handleReorderClick = (notice) => {
    setSelectedNotice(notice);
    setShowReorderModal(true);
  };

  const handleMoveUp = async () => {
    if (!selectedNotice) return;
    const index = notices.findIndex((n) => n.id === selectedNotice.id);
    if (index > 0) {
      const newOrder = notices[index - 1].order;
      await updateDoc(doc(db, 'notices', selectedNotice.id), {
        order: newOrder - 1
      });
    }
    setShowReorderModal(false);
  };

  const handleMoveDown = async () => {
    if (!selectedNotice) return;
    const index = notices.findIndex((n) => n.id === selectedNotice.id);
    if (index < notices.length - 1) {
      const newOrder = notices[index + 1].order;
      await updateDoc(doc(db, 'notices', selectedNotice.id), {
        order: newOrder + 1
      });
    }
    setShowReorderModal(false);
  };

  const handleMoveToPosition = async () => {
    if (!selectedNotice) return;
    const newPos = parseInt(movePosition, 10);
    if (isNaN(newPos) || newPos < 1 || newPos > notices.length) {
      alert('Vnesite veljavno pozicijo.');
      return;
    }
    const newOrder = notices[newPos - 1].order + 1;
    await updateDoc(doc(db, 'notices', selectedNotice.id), { order: newOrder });
    setShowReorderModal(false);
  };

  return (
    <>
      <div
        className="notice-list-container"
        ref={noticesContainerRef}
        onScroll={handleScroll}
      >
        <ListGroup className="notice-list">
          {notices.map((notice) => {
            return (
              <ListGroup.Item
                key={notice.id}
                className="notice-list-item"
                // The entire item is clickable
                onClick={() => onSelectNotice(notice)}
              >
                <Row>
                  {/* If there's a thumbnail, show it */}
                  {notice.thumbnailUrl && (
                    <Col md={2}>
                      <img
                        src={notice.thumbnailUrl}
                        alt="Thumbnail"
                        className="notice-thumbnail img-fluid"
                      />
                    </Col>
                  )}
                  <Col
                    md={notice.thumbnailUrl ? 10 : 12}
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{notice.title}</div>
                      <small>Ustvaril {notice.displayName || 'Anonymous'}</small>
                    </div>
                    <Badge bg="info" className="ms-2">
                      {notice.unit === 'general'
                        ? 'Splošno'
                        : notice.unitName || unitNames[notice.unit] || 'Splošno'}
                    </Badge>
                    <small className="text-muted ms-2">
                      {notice.createdAt
                        ? new Date(notice.createdAt).toLocaleString()
                        : ''}
                    </small>
                    {/* If unread, show red dot */}
                    {unreadNotices.includes(notice.id) && (
                      <Badge bg="danger" className="ms-2" pill>
                        &#8226;
                      </Badge>
                    )}
                  </Col>
                </Row>

                {/* Admin-only edit/delete + reorder */}
                {isAdmin && (
                  <Row>
                    <Col md={12} className="d-flex ms-2 my-2">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleReorderClick(notice);
                        }}
                      >
                        <FaSort /> Vrstni red
                      </Button>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditNotice(notice);
                        }}
                      >
                        <FaEdit /> Uredi
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(notice.id);
                        }}
                      >
                        <FaTrash /> Izbriši
                      </Button>
                    </Col>
                  </Row>
                )}
              </ListGroup.Item>
            );
          })}
          <div ref={noticesEndRef} />
        </ListGroup>
      </div>

      {/* Reorder Modal */}
      <Modal
        show={showReorderModal}
        onHide={() => setShowReorderModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Uredi vrstni red obvestila</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" className="mb-2" onClick={handleMoveUp}>
            Premakni gor
          </Button>
          <Button variant="secondary" className="mb-2 ms-2" onClick={handleMoveDown}>
            Premakni dol
          </Button>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>
                Premakni na pozicijo (1-{notices.length}):
              </Form.Label>
              <Form.Control
                type="number"
                min="1"
                max={notices.length}
                value={movePosition}
                onChange={(e) => setMovePosition(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleMoveToPosition}>
              Potrdi premik
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NoticeList;