// Profile.js

import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
import { db, auth, storage } from "../../firebase/firebase-config"; 
import { Button, Form, Container, Row, Col, Image, Alert, Spinner } from 'react-bootstrap';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { reauthenticateWithCredential, updatePassword, EmailAuthProvider } from "firebase/auth";
import User from "../../models/User"; // Adjust path if needed

function Profile({ onProfileComplete }) {
  // Profile State
  const [profile, setProfile] = useState(new User({}));
  const [editing, setEditing] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [units, setUnits] = useState([]);
  const [profileComplete, setProfileComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  // Password Change State
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");

  // Activation Message State
  const wasIncompleteRef = useRef(false);
  const [showActivationMessage, setShowActivationMessage] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!auth.currentUser) return; // Shouldn't happen if user is logged in

      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userProfile = User.fromFirestore(docSnap);
          setProfile(userProfile);
          setIsAdmin(userProfile.role === "admin");

          // Check if profile was complete or not
          const complete = isProfileComplete(userProfile);
          setProfileComplete(complete);
          wasIncompleteRef.current = !complete; 
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
      setLoading(false);
    };

    const fetchUnits = async () => {
      try {
        const unitsCollectionRef = collection(db, "units");
        const unitsSnapshot = await getDocs(unitsCollectionRef);
        const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUnits(unitsList);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchProfile();
    fetchUnits();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => {
      const updatedProfile = { ...prev, [name]: value };

      // Automatically update displayName based on firstName + lastName
      if (name === 'firstName' || name === 'lastName') {
        updatedProfile.displayName = `${updatedProfile.firstName} ${updatedProfile.lastName}`.trim();
      }

      return new User(updatedProfile); 
    });
  };

  // Helper function to check profile completeness
  const isProfileComplete = (userProfile) => {
    return (
      userProfile.firstName?.trim() &&
      userProfile.lastName?.trim() &&
      userProfile.phone?.trim()
    );
  };

  const handleSave = async () => {
    if (!auth.currentUser) return;

    try {
      // Save the updated profile to Firestore
      const docRef = doc(db, "users", auth.currentUser.uid);
      await setDoc(docRef, profile.toFirestore(), { merge: true });

      setEditing(false);

      // Check if the profile is complete
      const nowComplete = isProfileComplete(profile);
      setProfileComplete(nowComplete);

      // If the profile was incomplete before but now is complete, and user is NOT admin, show the activation message.
      if (wasIncompleteRef.current && nowComplete && !isAdmin) {
        setShowActivationMessage(true);
      }

      // Update the wasIncompleteRef to reflect the new state
      wasIncompleteRef.current = !nowComplete;

      // If there's an external callback for "profile complete", call it
      if (nowComplete && typeof onProfileComplete === 'function') {
        onProfileComplete();
      }

      // Optionally, you can reset password fields and messages upon saving profile
      setPasswordError("");
      setPasswordSuccess("");
    } catch (error) {
      console.error("Error saving profile:", error);
      // Optionally, handle profile save errors here
    }
  };

  const handleImageUpload = async (event) => {
    if (!auth.currentUser) return;
    const file = event.target.files[0];
    if (!file) return;

    try {
      const storageRefInstance = ref(storage, `profileImages/${auth.currentUser.uid}`);
      const snapshot = await uploadBytes(storageRefInstance, file);
      const photoURL = await getDownloadURL(snapshot.ref);

      setProfile(prev => new User({ ...prev, photoURL }));
      // Immediately persist to Firestore
      const docRef = doc(db, "users", auth.currentUser.uid);
      await setDoc(docRef, { photoURL }, { merge: true }); 
    } catch (error) {
      console.error("Failed to upload image:", error);
      // Optionally, handle image upload errors here
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setPasswordSuccess("");

    if (!auth.currentUser) {
      setPasswordError("User is not logged in.");
      return;
    }

    if (!currentPassword || !newPassword) {
      setPasswordError("Prosimo, izpolnite vsa polja za spremembo gesla.");
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
      setPasswordSuccess("Geslo je bilo uspešno posodobljeno!");
      setCurrentPassword("");
      setNewPassword("");
    } catch (error) {
      // Customize error messages based on error.code if needed
      let errorMessage = "Prišlo je do napake pri spreminjanju gesla.";
      if (error.code === 'auth/wrong-password') {
        errorMessage = "Trenutno geslo je napačno.";
      } else if (error.code === 'auth/weak-password') {
        errorMessage = "Novo geslo je prešibko. Prosimo, uporabite močnejše geslo.";
      }
      setPasswordError(errorMessage);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Nalaganje...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="full-bg">
      <Container className="mt-3">
        <Row className="justify-content-between align-items-center mb-3">
          <Col>
            <h1>Profil uporabnika</h1>
          </Col>
          <Col className="text-end">
            <Button
              onClick={() => setEditing(!editing)}
              variant={editing ? "secondary" : "primary"}
            >
              {editing ? "Prekliči" : "Uredi profil"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="text-center mb-3">
            <Image
              src={profile.photoURL || "https://via.placeholder.com/300"} // Placeholder if no image
              roundedCircle
              style={{
                width: "300px",
                height: "300px",
                objectFit: "cover"
              }}
            />
            {editing && (
              <Form.Group controlId="formFile" className="mb-3 mt-2">
                <Form.Label>Naloži profilno sliko</Form.Label>
                <Form.Control type="file" onChange={handleImageUpload} accept="image/*" />
              </Form.Group>
            )}
          </Col>
          <Col md={8}>
            <Form>
              {/* First Name */}
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>Ime</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vnesite ime"
                  name="firstName"
                  value={profile.firstName}
                  onChange={handleChange}
                  readOnly={!editing}
                  required
                />
              </Form.Group>

              {/* Last Name */}
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Priimek</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Vnesite priimek"
                  name="lastName"
                  value={profile.lastName}
                  onChange={handleChange}
                  readOnly={!editing}
                  required
                />
              </Form.Group>

              {/* Phone Number */}
              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Telefonska številka</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Vnesite telefonsko številko"
                  name="phone"
                  value={profile.phone}
                  onChange={handleChange}
                  readOnly={!editing}
                  required
                />
              </Form.Group>

              {/* Unit - only if admin */}
              {isAdmin && (
                <Form.Group className="mb-3" controlId="formUnit">
                  <Form.Label>Enota</Form.Label>
                  <Form.Select
                    name="unit"
                    value={profile.unit || ""}
                    onChange={handleChange}
                    disabled={!editing}
                  >
                    <option value="">Izberi enoto</option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unitName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

              {/* Role - only if admin and editing */}
              {isAdmin && editing && (
                <Form.Group className="mb-3" controlId="formRole">
                  <Form.Label>Vloga</Form.Label>
                  <Form.Select
                    name="role"
                    value={profile.role}
                    onChange={handleChange}
                    disabled={!editing}
                  >
                    <option value="user">Uporabnik</option>
                    <option value="admin">Admin</option>
                  </Form.Select>
                </Form.Group>
              )}

              {/* Save Button */}
              {editing && (
                <Button variant="success" onClick={handleSave}>
                  Shrani
                </Button>
              )}
            </Form>

            {/* Profile Incomplete Warning */}
            {!profileComplete && !editing && (
              <Alert variant="warning" className="mt-3">
                <Alert.Heading>Dokončajte svoj profil</Alert.Heading>
                <p>
                  Prosimo, izpolnite vse podatke o profilu (ime, priimek in telefonsko številko), 
                  da boste lahko nadaljevali z uporabo portala.
                </p>
              </Alert>
            )}

            {/* Activation Message for New Members */}
            {showActivationMessage && !isAdmin && (
              <Alert variant="info" className="mt-3">
                Vaš profil je bil uspešno shranjen. 
                V roku 24 ur bo vaš profil potrjen in boste lahko v celoti uporabljali sindikalni portal.
              </Alert>
            )}

            {/* Change Password Section */}
            {editing && (
              <>
                <h3 className="mt-4">Spremeni geslo</h3>
                <Form onSubmit={handlePasswordChange}>
                  {/* Current Password */}
                  <Form.Group className="mb-3" controlId="formCurrentPassword">
                    <Form.Label>Trenutno geslo</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Vnesite trenutno geslo"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      required
                    />
                  </Form.Group>

                  {/* New Password */}
                  <Form.Group className="mb-3" controlId="formNewPassword">
                    <Form.Label>Novo geslo</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Vnesite novo geslo"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      minLength={6} // Firebase requires a minimum length of 6
                    />
                    <Form.Text className="text-muted">
                      Novo geslo mora vsebovati vsaj 6 znakov.
                    </Form.Text>
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Spremeni geslo
                  </Button>

                  {/* Success Message */}
                  {passwordSuccess && (
                    <Alert variant="success" className="mt-3">
                      {passwordSuccess}
                    </Alert>
                  )}

                  {/* Error Message */}
                  {passwordError && (
                    <Alert variant="danger" className="mt-3">
                      {passwordError}
                    </Alert>
                  )}
                </Form>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// Provide a default no-op function so there's no error if not provided
Profile.defaultProps = {
  onProfileComplete: () => {},
};

export default Profile;
