import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebase/firebase-config';
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  orderBy,
  limit
} from 'firebase/firestore';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  ProgressBar
} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './NoticeCreate.css';
import { useUser } from '../../context/UserContext';

import {
  getAllUserEmails,
  getUserEmailsByUnit,
  sendMassEmail,
  sendMassEmailTest
} from '../../utils/emailUtils';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
};

function NoticeCreate({ onNoticeCreated, noticeToEdit }) {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [unit, setUnit] = useState('general');
  const [unitName, setUnitName] = useState('Splošno');
  const [units, setUnits] = useState([]);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [thumbnail, setThumbnail] = useState(null); // thumbnail
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // NEW: email toggles
  const [sendEmailAll, setSendEmailAll] = useState(false);
  const [sendEmailUnit, setSendEmailUnit] = useState(false);

  // Track the maximum "order" currently in the notices collection
  const [maxOrder, setMaxOrder] = useState(0);

  const { user } = useUser();

  // ─────────────────────────────────────────────────────────────
  // Pre-fill if editing:
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (noticeToEdit) {
      setTitle(noticeToEdit.title);
      setText(noticeToEdit.text);
      setUnit(noticeToEdit.unit || 'general');
      setUnitName(noticeToEdit.unitName || 'Splošno');
      setFileUrl(noticeToEdit.fileUrl || '');
    }
  }, [noticeToEdit]);

  // ─────────────────────────────────────────────────────────────
  // Fetch units
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchUnits = async () => {
      const unitsCollectionRef = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollectionRef);
      const unitsList = unitsSnapshot.docs.map(docItem => ({
        id: docItem.id,
        ...docItem.data()
      }));
      setUnits(unitsList);
    };
    fetchUnits();
  }, []);

  // ─────────────────────────────────────────────────────────────
  // Fetch the max current "order" from Firestore 
  // so we can create new notices with "order = maxOrder + 1"
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchMaxOrder = async () => {
      try {
        // Sort the notices by "order" descending, limit to 1
        const qOrderDesc = query(
          collection(db, 'notices'),
          orderBy('order', 'desc'),
          limit(1)
        );
        const qsnap = await getDocs(qOrderDesc);
        if (!qsnap.empty) {
          const docSnap = qsnap.docs[0];
          const data = docSnap.data();
          setMaxOrder(data.order || 0);
        } else {
          setMaxOrder(0);
        }
      } catch (error) {
        console.error('Failed to fetch max order:', error);
      }
    };
    fetchMaxOrder();
  }, []);

  // ─────────────────────────────────────────────────────────────
  // Handle unit selection
  // ─────────────────────────────────────────────────────────────
  const handleUnitChange = (e) => {
    const selectedUnitId = e.target.value;
    const selectedUnit = units.find(u => u.id === selectedUnitId);
    setUnit(selectedUnitId);
    setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
  };

  // ─────────────────────────────────────────────────────────────
  // Validate thumbnail
  // ─────────────────────────────────────────────────────────────
  const handleThumbnailChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage && !selectedImage.type.startsWith('image/')) {
      setError('Only image files are allowed for the thumbnail.');
      setThumbnail(null);
    } else {
      setError('');
      setThumbnail(selectedImage);
    }
  };

  // ─────────────────────────────────────────────────────────────
  // MAIN SUBMIT
  // ─────────────────────────────────────────────────────────────
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a notice.');
      return;
    }
    if (!title.trim() || !text.trim()) {
      setError('Title and text cannot be empty.');
      return;
    }

    let fileDownloadUrl = fileUrl;

    // If we have a new file to upload
    if (file) {
      setUploading(true);
      try {
        const storageRef = ref(storage, `notices/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            snapshot => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            err => {
              console.error('Upload error:', err);
              setError('Failed to upload file. Please try again.');
              setUploading(false);
              reject(err);
            },
            async () => {
              fileDownloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } catch (error) {
        // Already handled
        return;
      }
    }

    // Upload thumbnail if provided
    let thumbnailUrl = '';
    if (thumbnail) {
      setUploading(true);
      try {
        const thumbnailRef = ref(storage, `notice-thumbnails/${thumbnail.name}`);
        const thumbnailUploadTask = uploadBytesResumable(thumbnailRef, thumbnail);

        await new Promise((resolve, reject) => {
          thumbnailUploadTask.on(
            'state_changed',
            snapshot => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            err => {
              console.error('Thumbnail upload error:', err);
              setError('Failed to upload thumbnail. Please try again.');
              setUploading(false);
              reject(err);
            },
            async () => {
              thumbnailUrl = await getDownloadURL(thumbnailUploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } catch (error) {
        // handled
        return;
      }
    }

    await saveNotice(fileDownloadUrl, thumbnailUrl);
  };

  // ─────────────────────────────────────────────────────────────
  // Firestore save + email logic
  // ─────────────────────────────────────────────────────────────
  const saveNotice = async (fileDownloadUrl, thumbnailUrl) => {
    // Build the object to store
    const newNotice = {
      title,
      text,
      unit,
      unitName,
      fileUrl: fileDownloadUrl || '',
      thumbnailUrl: thumbnailUrl || ''
    };

    // If creating a new notice => add createdAt, uid, etc. 
    // and set order = maxOrder + 1
    if (!noticeToEdit) {
      newNotice.createdAt = new Date();
      newNotice.uid = user.uid;
      newNotice.displayName = user.displayName || 'Anonymous';
      newNotice.order = maxOrder + 1; // <--- Set the "order" field
    }

    try {
      if (noticeToEdit) {
        // Updating existing
        await updateDoc(doc(db, 'notices', noticeToEdit.id), newNotice);
      } else {
        // Creating new
        await addDoc(collection(db, 'notices'), newNotice);
      }

      // Build email content
      const subject = `Novo obvestilo: ${title}`;
      const html = `
        <h2>Pozdravljeni član ali članica sindikata KSZS!</h2>
        <p>
          Na našem sindikalnem portalu 
          <a href="https://portal.kszs.si/notices">https://portal.kszs.si/obvestila</a> 
          je bilo objavljeno novo obvestilo sindikata z naslovom 
          <strong>${title}</strong>.
        </p>
        <p>
          Vzemite si nekaj časa in se seznanite z vsebino obvestila ter aktivnostmi sindikata.
        </p>
        <p>Skupaj smo močnejši!</p>
        <p>Lep pozdrav,<br/>Konferenca sindikatov zavarovalništva Slovenije</p>
      `;

      // Decide who to email
      try {
        const recipientsSet = new Set();

        // If "sendEmailAll" is ON => gather all user emails
        if (sendEmailAll) {
          const allUserEmails = await getAllUserEmails(db);
          allUserEmails.forEach(email => recipientsSet.add(email));
        }

        // If "sendEmailUnit" is ON => gather unit's emails
        if (sendEmailUnit && unit) {
          const unitEmails = await getUserEmailsByUnit(db, unit);
          unitEmails.forEach(email => recipientsSet.add(email));
        }

        // If we have any recipients => send
        if (recipientsSet.size > 0) {
          await sendMassEmail(subject, html, Array.from(recipientsSet));
        }
      } catch (emailError) {
        console.error('Error sending email notifications:', emailError);
      }

      // Reset form
      setTitle('');
      setText('');
      setUnit('general');
      setUnitName('Splošno');
      setFile(null);
      setFileUrl('');
      setThumbnail(null);
      setError('');
      setUploading(false);

      // Reset toggles
      setSendEmailAll(false);
      setSendEmailUnit(false);

      onNoticeCreated();
    } catch (error) {
      setError('Failed to create or update notice. Please try again.');
      console.error('Error creating/updating notice:', error);
      setUploading(false);
    }
  };

  return (
    <Container className="notice-create-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Naslov</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vpišite naslov"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Besedilo</Form.Label>
              <div className="quill-container">
                <ReactQuill
                  value={text}
                  onChange={setText}
                  placeholder="Vpišite obvestilo"
                  theme="snow"
                  modules={modules}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Enota</Form.Label>
              <Form.Control as="select" value={unit} onChange={handleUnitChange}>
                <option value="general">Splošno</option>
                {units
                  .filter(u => user.role === 'admin' || u.id === user.unit)
                  .map(u => (
                    <option key={u.id} value={u.id}>
                      {u.unitName}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* File Upload */}
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Datoteka (PDF)</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Thumbnail Upload */}
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Ikona obvestila (thumbnail)</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleThumbnailChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* NEW: Switches for emailing */}
        <Row className="mb-3">
          <Col>
            <Form.Check
              type="switch"
              id="sendEmailAllSwitch"
              label="Pošlji email vsem uporabnikom"
              checked={sendEmailAll}
              onChange={(e) => setSendEmailAll(e.target.checked)}
            />
          </Col>
          <Col>
            <Form.Check
              type="switch"
              id="sendEmailUnitSwitch"
              label="Pošlji email samo uporabnikom iz te enote"
              checked={sendEmailUnit}
              onChange={(e) => setSendEmailUnit(e.target.checked)}
            />
          </Col>
        </Row>

        {uploading && (
          <ProgressBar
            now={uploadProgress}
            label={`${Math.round(uploadProgress)}%`}
            striped
            className="mb-3"
          />
        )}

        <Button variant="danger" type="submit" disabled={uploading}>
          {noticeToEdit ? 'Posodobi obvestilo' : 'Objavi obvestilo'}
        </Button>
      </Form>
    </Container>
  );
}

export default NoticeCreate;